<template>
  <div class="wh-space-bottom">
    <b-card>
      <b-tabs ref="tabs" :nav-wrapper-class="{'d-none': reports.length === 1}">

        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon"/>
            <span>{{ $t('common.all') }}</span>
          </template>
        </b-tab>

        <b-tab v-for="(report) in reports">
          <template #title>
            <feather-icon icon="FileTextIcon"/>
            <span>{{ report.title }}</span>
          </template>
          <div class="reportWrapper" :class="{'m-2': reports.length > 1}">
            <edit-field-header
              :title="report.title"
              :show-back-btn="reports.length === 1"
              :show-hr="reports.length === 1"
              @routerBack="$router.back()"
            />
            <edit-field
              :isEdit="true"
              :fields="report.fields"
              :row-data="search[report.type]"
              :postApi="report.api"
              :submitBtnText="$t('common.download_report')"
              :submit-action="generateReport"
              :showHrBeforeSubmitBtn="reports.length === 1"
              :permission="report.permission"
            />
            <hr class="w-100"/>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ButtonPermission from '@/components/ButtonPermission'
import TableSearch from '@/components/TableSearch'
import EditFieldHeader from '@/components/EditFieldHeader'
import EditField from '@/components/EditField2'

export default {
  name: 'kioskReport',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,
    TableSearch,
    EditFieldHeader,
    EditField,
  },

  data() {
    return {
      view_permission: common.checkPermission('Kiosk_Menu_Health_Data'),
      // view_permission: false,
      is_admin: common.isAdmin(),

      memberId: this.$router.currentRoute.params.memberId,
      memberCode: null,
      facilityId: null,

      time_option: [],

      search_fields: [],
      search_default: {},

      // 用戶填寫的數據，用以提交表單
      search: {
        HealthData: {},
      },

      // 表單的默認信息
      reports: [
        {
          title: '會員5分鐘健康數據報表',
          type: 'HealthData',
          permission: 'Kiosk_Menu_Health_Data',
          api: '/kioskmanage/ReportRecentSummaryPer5Mins',
          fields: [
            {
              label: 'member.chineseName',
              name: 'chineseName',
              type: 'text',
              rule: '',
              edit_disable: true,
            },
            {
              label: 'common.bed',
              name: 'bedNumber',
              type: 'text',
              rule: '',
              edit_disable: true,
            },
            {
              label: 'member.sex',
              name: 'isMale',
              type: 'text',
              rule: '',
              edit_disable: true,
            },
            {
              label: 'device_report.start_date',
              name: 'startDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                minDate: null,
                maxDate: this.$moment().add(1, 'hours').format('YYYY-MM-DD'),
              },
              default: this.$moment().add(-6, 'days').format('YYYY-MM-DD'),
              on_change: ({ dateType, dateStr }) => {
                this.handleDateChange({
                  formType: 'HealthData', dateType, dateStr
                })
              },
            },
            {
              label: 'device_report.end_date',
              name: 'endDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                minDate: null,
                maxDate: this.$moment().add(1, 'hours').format('YYYY-MM-DD'),
              },
              default: this.$moment().add(1, 'hours').format('YYYY-MM-DD'),
              on_change: ({ dateType, dateStr }) => {
                this.handleDateChange({
                  formType: 'HealthData', dateType, dateStr
                })
              },
            },
          ]
        },
      ]
    }
  },

  mounted() {
    if (!this.view_permission) return

    this.memberId = common.decrypt(this.memberId)
    if (this.memberId === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }

    // get member info
    this.$requestWehealth({
      method: 'get',
      url: '/member/GetMember',
      params: { memberID: this.memberId }
    }).then(({ data }) => {
      const { member } = data
      this.memberCode = member.memberCode
      this.facilityId = member.facilityID
      this.$set(this.search.HealthData, 'chineseName', member.chineseName)
      this.$set(this.search.HealthData, 'bedNumber', member.bedNumber)
      this.$set(this.search.HealthData, 'isMale', member.isMale ? common.getI18n('member.sex_male') : common.getI18n('member.sex_female'))
    })

    // initialize time_option
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? '0' + i : i
      const time = hour + ':00'
      this.time_option.push({
        text: time,
        value: time
      })
    }

    // set default value from fields to forms
    this.reports.forEach(report => {
      report.fields.forEach(field => {
        if (field.default !== undefined && field.name) {
          this.$set(this.search[report.type], field.name, field.default)
        }
      })
    })
  },

  methods: {

    handleDateChange({
      formType,
      dateType,
      dateStr
    }) {
      const reportIndex = this.reports.findIndex(report => report.type === formType)
      const fields = this.reports[reportIndex].fields
      const startDateIndex = fields.findIndex(field => field.name === 'startDate')
      const endDateIndex = fields.findIndex(field => field.name === 'endDate')
      common.syncFieldsOfDateRange({
        dayRange: 365,
        changedDateType: dateType,
        changedDateStr: dateStr,
        vmDateWrapperObj: this.search[formType],
        vmStartDateConfig: startDateIndex >= 0 ? fields[startDateIndex].config : undefined,
        vmEndDateConfig: endDateIndex >= 0 ? fields[endDateIndex].config : undefined,
      })
    },

    generateReport: function (data, api) {
      const where = data
      where.startTime = where.startTime === undefined ? '00:00' : where.startTime
      where.endTime = where.endTime === undefined ? '23:59' : where.endTime

      const startMoment = this.$moment(`${where.startDate} ${where.startTime}`)
      const endMoment = where.endDate ? this.$moment(`${where.endDate} ${where.endTime}`) : this.$moment(`${where.startDate} ${where.endTime}`)
      if (endMoment.diff(startMoment) > 0) {
        const params = {
          facilityID: this.facilityId,
          memberCode: this.memberCode,
          endDate: where.endDate,
          startDate: where.startDate
        }
        common.apiDownloadData({
          url: api,
          params
        })
      } else {
        common.showError('ReportConditionTimeError')
      }
    },

    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: true
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 3rem 0;
}

.tabs > :first-child {
  margin-bottom: 3rem;
}

.tab-content .tab-pane {
  &.active:first-child ~ .tab-pane {
    display: block !important;
  }

  &.active, &:last-of-type {
    > .reportWrapper > hr {
      display: none;
    }
  }
}

</style>
